<template>
    <div>
        <a-spin :spinning="loading">
            <a-table
                :columns="colums"
                :dataSource="tableData"
                bordered
                :pagination="false">
                <template slot="title">
                    <a-button icon="plus" type="primary" @click="$refs.addRoleForm.show(null)">添加角色</a-button>
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-button size="small" type="primary" class="action" @click="modify(record)">修改</a-button>
                    <a-popconfirm
                        title="确认删除?"
                        ok-text="确认"
                        cancel-text="取消"
                        @confirm="del(record)">
                        <a-button size="small" type="info" class="action">删除</a-button>
                    </a-popconfirm>
                </template>
            </a-table>
        </a-spin>
        <add-role-form ref="addRoleForm" @success="findRolesByUser()"/>
        <update-role-form ref="updateRoleForm" @success="findRolesByUser()"/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import AddRoleForm from "@/views/sys/modules/AddRoleForm"
import UpdateRoleForm from "@/views/sys/modules/UpdateRoleForm"
const colums = [
    { title: '角色名称', dataIndex: 'roleName'},
    { title: '角色描述', dataIndex: 'roleDesc'},
    { title: '操作', scopedSlots: { customRender: 'action' } }
]
export default {
    components: {
        AddRoleForm,
        UpdateRoleForm
    },
    data () {
        return {
            loading: false,
            tableData: [],
            colums
        }
    },
    computed: {
        ...mapState({
        })
    },
    mounted () {
        this.findRolesByUser()
    },
    methods: {
        findRolesByUser () {
            this.loading = true
            this.$api.role.findRolesByUser().then(res => {
                if (res.status === 0) {
                    this.tableData = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        modify (record) {
            this.$refs.updateRoleForm.show(record)
        },
        del (record) {
            this.loading = true
            this.$api.role.delRole(record.id).then(res => {
                if (res.status === 0) {
                    this.$message.success('删除成功')
                    this.findRolesByUser()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        }
    }
}
</script>
<style scoped>
    .action {
        margin-right: 10px;
    }
</style>
