<!-- 添加修改角色信息 -->
<template>
    <a-modal
        :title="title"
        :visible.sync="visible"
        :width="600"
        okText="确定"
        cancelText="取消"
        @cancel="close()"
        @ok="postData()">
        <a-spin :spinning="loading">
            <a-form ref="form" :form="form">
                <a-form-item label="角色名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入角色名称" v-decorator="['roleName', {rules: ['roleName',{required: true, min:1, message: '请输入角色名称'}], trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="角色描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入角色描述，最多50个字符" v-decorator="['roleDesc', {rules: ['id',{required: true, min:1, max:50, message: '请输入角色描述,最多50个字符'}], trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="选择菜单" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-tree
                        :tree-data="menuData"
                        :replaceFields="replaceFields"
                        defaultExpandAll
                        defaultExpandParent
                        checkable
                        style="width:500px;"
                        @check="nodeCheck">
                        <template slot="custom" slot-scope="node">
                            <span class="node-title">
                                <a-icon type="folder" v-if="node.dataRef.type === 1" theme="twoTone" two-tone-color="red"/>
                                <a-icon type="file" v-if="node.dataRef.type === 2" theme="twoTone" two-tone-color="blue"/>
                                <a-icon type="down-circle" v-if="node.dataRef.type === 3" theme="twoTone" two-tone-color="green"/>
                                {{ node.name }}
                            </span>
                        </template>
                    </a-tree>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>
<script>
export default {
    data () {
        return {
            title: '添加角色',
            visible: false,
            loading: false,
            menuLoading: false,
            menuData: [],
            labelCol: { lg: { span: 4 }, sm: { span: 18 } },
            wrapperCol: { lg: { span: 17 }, sm: { span: 10 } },
            replaceFields: { children:'children',title:'name', key:'id' },
            form: this.$form.createForm(this),
            formData: {
                id: '',
                roleName: '',
                roleDesc: '',
                checkMenus: []
            }
        }
    },
    methods: {
        show (schoolId) {
            this.visible = true
            this.findManagerMenus()
        },
        findManagerMenus () {
            this.menuLoading = true
            this.$api.menu.findNavMenuTree().then(res => {
                if (res.status === 0) {
                    this.menuData = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => { this.menuLoading = false })
        },
        close () {
            this.loading = false
            this.visible = false
            this.formData = {
                roleName: '',
                roleDesc: '',
                checkMenus: []
            }
            this.form.resetFields()

        },
        postData () {
            if (this.formData.checkMenus.length === 0) {
                this.$message.warning('请选择菜单')
                return
            }
            this.form.validateFields((error, values) => {
                if (!error) {
                    this.formData.roleName = values['roleName']
                    this.formData.roleDesc = values['roleDesc']
                    this.saveRole()
                }
            })
        },
        nodeCheck (checkedKeys, e) {
            let checkMenus = []
            if (e.checkedNodes.length > 0) {
                e.checkedNodes.forEach(item => {
                    let data = item.data.props.dataRef
                    var parentId = data.parentId
                    var menuId = data.id
                    if (parentId !== 0) {
                        checkMenus.push(parentId)
                    }
                    checkMenus.push(menuId)
                })
            }
            this.formData.checkMenus = Array.from(new Set(checkMenus))
        },
        saveRole () {
            this.loading = true
            this.$api.role.addRole(this.formData).then(res => {
                this.loading = false
                if (res.status === 0) {
                    this.$message.success('添加成功')
                    this.$emit('success')
                    this.close()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.loading = false
                console.log(err)
                this.$message.error('网络忙')
            })
        }
    }
}
</script>
